<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="bankGuarantee"
          :listBank="listBank"
          :listInsurance="listInsurance"
          ref="formEdit"
          v-if="!edit"
        ></form-edit>
        <detail :form="bankGuarantee" v-if="edit"></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateItem">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && !status">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="done">
                  <v-icon>
                    mdi-check-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Done</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && !status">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && !status">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click.stop="deleteDialog">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="bankGuarantee.formNumber"
      title="Bank Guarantee"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
  </form>
</template>

<script>
import Detail from "./DetailBankGuarantee";
import FormEdit from "./FormBankGuarantee";
import DialogDelete from "@/components/DialogDelete";
import { mapState } from "vuex";

export default {
  name: "bank-guarantee-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-delete": DialogDelete,
  },
  props: {
    id: Number,
  },
  data: () => ({
    edit: true,
    dialog: false,
    bankGuarantee: {},
  }),
  computed: {
    ...mapState("bank", ["listBank"]),
    ...mapState("insurance", ["listInsurance"]),
    status() {
      return (
        this.bankGuarantee.statusList[this.bankGuarantee.statusList.length - 1].status === "Done"
      );
    },
  },
  methods: {
    async getBankGuarantee() {
      await this.$store.dispatch("bankGuarantee/getById", this.id).then(response => {
        this.bankGuarantee = response.data;
      });
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.update();
    },
    deleteItem(id) {
      this.$store.dispatch("bankGuarantee/delete", id);
      this.dialog = false;
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    cancel() {
      this.getBankGuarantee();
      this.edit = true;
    },
    async done() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("bankGuarantee/done", { id: this.id, status: "Done" })
        .then(() => {
          this.$store.commit("SET_LOADER", false);
          window.location.reload();
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getBankGuarantee(),
        await this.$store.dispatch("bank/dropdown"),
        await this.$store.dispatch("insurance/dropdown"),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
