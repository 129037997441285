<template>
  <v-dialog v-model="dialog" max-width="1200" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">List Of Files</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          sort-by="no"
          dense
          disable-pagination
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat v-if="!detail">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-row>
                    <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                      <v-subheader>Document Upload</v-subheader>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" lg="6" class="py-0">
                      <v-file-input
                        outlined
                        flat
                        dense
                        background-color="white"
                        v-model="files"
                        multiple
                      ></v-file-input>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" lg="2" class="py-0">
                      <v-btn color="success" @click="add">Add</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.no="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn color="error" icon @click.stop="openDialog(item.id)" v-if="!detail">
              <v-icon>
                mdi-trash-can
              </v-icon>
            </v-btn>
            <v-btn color="success" icon @click.stop="download(item.fileName)" v-if="detail">
              <v-icon>
                mdi-download
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
    <dialog-confirm-file
      :dialog="dialogConfrim"
      :id="fileId"
      @deleteFile="deleteItem"
      @close="closeConfirm"
    ></dialog-confirm-file>
  </v-dialog>
</template>

<script>
import DialogConfirmFile from "./DialogConfirmFile.vue";
import fileDownload from "js-file-download";

export default {
  name: "dialog-view-bank",
  props: {
    dialog: Boolean,
    items: Array,
    id: Number,
    detail: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DialogConfirmFile,
  },
  data() {
    return {
      files: [],
      dialogConfrim: false,
      fileId: null,
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "File Name",
          value: "fileName",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    openDialog(id) {
      this.fileId = id;
      this.dialogConfrim = true;
    },
    closeConfirm() {
      this, (this.dialogConfrim = false);
    },
    async deleteItem(id) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("bankGuarantee/deleteFile", id)
        .then(() => {
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async add() {
      this.$store.commit("SET_LOADER", true);
      const formData = new FormData();
      this.files.forEach(x => {
        formData.append("files", x);
      });
      let body = {
        id: this.id,
        formData: formData,
      };
      await this.$store
        .dispatch("bankGuarantee/createFile", body)
        .then(() => {
          this.$store.commit("SET_LOADER", false);
        })
        .catch(error => {
          if (error.response.status === 400) {
            this.$store.dispatch("toast", {
              type: "error",
              message: error.response.data,
            });
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
          }
          this.$store.commit("SET_LOADER", false);
        });
    },
    async download(fileName) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("bankGuarantee/download", { fileName: fileName })
        .then(response => {
          fileDownload(response.data, fileName);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    close() {
      this.$emit("close");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
